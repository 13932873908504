import React, { useMemo } from 'react';
import { Stack, Grid, Box } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from './styles.module.scss'

/**
 * TwoColumnsData
 * @param {*} param0
 * @returns
 */
function TwoColumnsData({ items, title, variant = 'normal' }) {
  const itemsToShow = useMemo(() => _.filter(items, (item) => !item?.isHidden), [items]);
  const {mb,mt,paddingBottom} = useMemo(() => {
    if (variant === 'normal') {
      return {
        mb: 3,
        mt: 3,
        paddingBottom: 3.5
      }
    }
    return {
      mb: 1.5,
      mt: 1.5,
      paddingBottom: 1.5
    }
  }, [variant]);
  return (
    <>
      {title && (
        <Box mb={mb} mt={mt}>
          <Typography variant={'body1'} color={'black'} weight='bold'>
            {title}
          </Typography>
        </Box>
      )}
      <Grid container spacing={0}>
        {_.map(itemsToShow, (item, index) => (
          <>
            <Grid item xs={4} sx={{paddingBottom: index === (itemsToShow.length - 1) ? 0 : paddingBottom}}>
              <Stack 
                direction="row" 
                justifyContent="flex-start"
                alignItems="center"
                spacing="1rem"
                mr={"16px"}
                sx={{
                  width: 'calc(100% - 16px)',
                }}
              >
                {item?.labelSufix}
                <Typography variant='m' className={classes.label}>
                  {item?.label}
                </Typography>
                {item?.labelPrefix}
              </Stack>
            </Grid>
            <Grid item xs={8} sx={{paddingBottom: index === (itemsToShow?.length - 1) ? 0 : paddingBottom}}>
              <Stack 
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing="1rem"
                mr={"16px"}
                sx={{
                  width: 'calc(100% - 16px)',
                }}
              >
                {!item?.custom ? (
                  <>
                    {item?.valueSufix}
                    <Typography variant='m' color={item?.disabledField ? "grey":undefined}>
                      {item?.value}
                    </Typography>
                    {item?.valuePrefix}
                  </>
                ) : (
                  <>
                    {item?.custom}
                  </>
                )}
              </Stack>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
}

TwoColumnsData.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string
};

TwoColumnsData.defaultProps = {
  items: []
};

export default TwoColumnsData;
