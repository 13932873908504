import React, { useEffect, useMemo } from 'react';
import {
  addAssistantText,
  clearAssistatText,
} from 'src/modules/generic/store/action';
import { AnalizerInputWithBanner } from 'src/components/inputs/AnalizerInputWithBanner';
import Button from 'src/modules/generic/components/Buttons/Button';
import CalendarInputDate from 'src/components/inputs/Calendars/CalendarInputDate';
import CountrySelector from 'src/components/inputs/Select/CountrySelector/CountrySelector';
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import Form from 'src/components/forms/Form/Form';
import Line from 'src/components/generic/Line/Line';
import PhoneInput from 'src/modules/generic/components/Inputs/PhoneInput';
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
import SubmitButton from 'src/components/generic/Buttons/SubmitButton/SubmitButton';
import TemplateFormDefault from 'src/modules/generic/templates/Modal';
import TextInput from 'src/components/inputs/Text/TextInput';
import _ from 'lodash';
import classes from './IndividualStakeholderForm.module.scss';
import { useDispatch } from 'react-redux';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import useStakeholderFieldsAnalizer from '../hooks/useStakeholderFieldsAnalizer';
import ClaraCountryAutocomplete from 'src/v1/components/Form/ClaraCountryAutocomplete/ClaraCountrySelector';
import Checkbox from 'src/components/inputs/Checkbox/Checkbox';
import RadioInput from 'src/components/inputs/Radio/RadioInput';

const CaymanRenewalIndividualStakeholderForm = ({
  initialValues = {},
  handleSubmit,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
  banners = false,
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const residenceCountry = _.get(initialValues, 'address.country.code')
    ? _.get(initialValues, 'address.country.code').toLowerCase()
    : '';

  const listTitle = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Ms', value: 'Ms' },
    { label: 'Dr', value: 'Dr' },
    { label: 'Hon', value: 'Hon' },
    { label: 'Reverend', value: 'Reverend' },
    { label: 'Very Reverend', value: 'Very Reverend' },
    { label: 'Senator', value: 'Senator' },
  ];

  useEffect(() => {
    banners &&
      dispatch(
        addAssistantText(
          "<strong>Additional fees</strong> might apply if you need to update some of the details with the Cayman registrar.</br><div style='margin-top:1rem;'>To make a change, just edit the content and proceed. Once a change is made, a request will be sent to Clara and a service agent will get back to you with the related invoice to pay and next steps to legally file the changes for your company.</div>",
          'alert'
        )
      );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const defaultValues = useMemo(() => {
    return {
      ...initialValues,
      nationality: _.get(
        initialValues,
        'nationality.code',
        initialValues.nationality
      ),
    };
  }, [JSON.stringify(initialValues)]);

  const { config, schema } = useStakeholderFieldsAnalizer(initialValues);

  return (
    <Form defaultValues={defaultValues} schema={schema} onSubmit={handleSubmit}>
      <Template
        buttons={{
          submit: (
            <SubmitButton>
              {translate('MODULES.CLARA.BTN_SAVE_CHANGES')}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={() => handleCloseModal()} variant="secondary">
              {translate('MODULES.CLARA.BTN_CANCEL')}
            </Button>
          ),
        }}
        props={propsTemplate}
      >
        <AnalizerInputWithBanner
          component={SelectInput}
          controllerInputProps={{
            name: 'title',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
            list: listTitle,
          }}
          field={config.title}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: 'fullName',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
          }}
          field={config.fullName}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: 'previousName',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
          }}
          field={config.previousName}
        />

        <span className={classes.separator}>
          <Line />
        </span>

        <AnalizerInputWithBanner
          component={CountrySelector}
          controllerInputProps={{
            name: 'nationality',
            placeholder: translate('MODULES.CLARA.SELECT_COUNTRY'),
          }}
          field={config.nationality}
        />
        <AnalizerInputWithBanner
          component={RadioInput}
          controllerInputProps={{
            name: 'hasNationalities'
          }}
          field={config.hasNationalities}
        />
        <AnalizerInputWithBanner
          component={ClaraCountryAutocomplete}
          controllerInputProps={{
            name: 'nationalities',
            placeholder: translate('MODULES.CLARA.SELECT_ALL_THAT_APPLY'),
          }}
          field={config.nationalities}
        />
        <AnalizerInputWithBanner
          component={CalendarInputDate}
          controllerInputProps={{
            name: 'dateOfBirth',
            placeholder: translate('MODULES.CLARA.ROLE_DATE_INPUT_HIT'),
          }}
          field={config.dateOfBirth}
        />
        <AnalizerInputWithBanner
          component={CountrySelector}
          controllerInputProps={{
            name: 'countryOfBirth',
            placeholder: translate('MODULES.CLARA.SELECT_COUNTRY'),
          }}
          field={config.countryOfBirth}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: 'cityOfBirth',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
          }}
          field={config.cityOfBirth}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: 'passportNumber',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
          }}
          field={config.passportNumber}
        />

        <span className={classes.separator}>
          <Line />
        </span>

        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: 'email',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
          }}
          field={config.email}
        />
        <AnalizerInputWithBanner
          component={PhoneInput}
          controllerInputProps={{
            name: 'phoneNumber',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
            defaultCountry: residenceCountry,
            disableDropdown: false,
          }}
          field={config.phoneNumber}
        />

        <span className={classes.separator}>
          <Line />
        </span>

        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: 'profession',
            placeholder: translate('MODULES.CLARA.EXAMPLE_PROFESSION'),
          }}
          field={config.profession}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: 'linkedinURL',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
          }}
          field={config.linkedinURL}
        />
        <AnalizerInputWithBanner
          component={FileUploaderInput}
          controllerInputProps={{
            name: 'curriculumVitae',
            placeholder: translate('MODULES.CLARA.TYPE_HERE_HIT'),
            initVal: _.get(initialValues, 'curriculumVitae'),
          }}
          field={config.curriculumVitae}
        />

        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default CaymanRenewalIndividualStakeholderForm;
