export const MAILING_ADDRESS_TYPE = {
  BUSINESS: 'BUSINESS',
  RESIDENTIAL: 'RESIDENTIAL'
}
export const MAILING_ADDRESS_TYPE_LABEL = {
  BUSINESS: 'Business',
  RESIDENTIAL: 'Residential'
}
const constants = {
  MAILING_ADDRESS_TYPE,
  MAILING_ADDRESS_TYPE_LABEL
};

export default constants;