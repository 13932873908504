import useGetGroupCompanyDetails from "./graphql/useGetGroupCompany";
import useGetPINCAPrices from 'src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices';
import useSession from "src/modules/session/hooks/useSession";
import useUpsertGroupCompanyDetails from "./graphql/useUpsertGroupCompanyDetails";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { manualQuery: getGroupCompany } = useGetGroupCompanyDetails({ groupCompanyId, startupId },{});
  const mutation = useUpsertGroupCompanyDetails();
  const { manualQuery: getPINCAPrices } = useGetPINCAPrices(
    {
      variables: {
        filters: {
          jurisdictionTypes: ['CAYMAN']
        }
      }
    }
  );

  const initialData = async () => {
    const { data: groupCompanyData } = await getGroupCompany();
    return { initialValues, groupCompany: groupCompanyData?.getGroupCompany };
  }
  
  const pincasData = async () => {
    const pincasData = await getPINCAPrices();
    return { pincasData };
  }

  const SaveGeneralDetails = async (context) => {
    if(initialValues?.managedByClara) {
      const { name, authorisedShareCapital, linkedinURL } = context?.EditGeneralDetailsManagedByClaraForm;

      const variables = {
        groupCompanyId,
        startupId,
        groupCompany: {
          name,
          authorisedShareCapital,
          linkedinURL
        }
      };

      await mutation({ variables });
    } else {
      const { name, country, jurisdiction, type, registeredNumber, incorporation_date, dissolution_date, transactionCurrency, authorisedShareCapital, purposeOfEntity: code, purposeOfEntityDescription: description, linkedinURL } = context?.EditGeneralDetailsForm;

      const variables = {
        groupCompanyId,
        startupId,
        groupCompany: {
          name,
          country,
          jurisdiction,
          type,
          registeredNumber,
          incorporation_date,
          dissolution_date,
          transactionCurrency,
          authorisedShareCapital,
          purposeOfEntity: {
            code,  
            description: !description ? '' : description
          },
          linkedinURL
        }
      };

      await mutation({ variables });
    }
  }

  return { initialData, SaveGeneralDetails, pincasData };
}

export default useServices;
