import React, { useMemo } from "react";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import * as Yup from "yup";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import ClaraCountryAutocomplete from "src/v1/components/Form/ClaraCountryAutocomplete/ClaraCountrySelector";

const schema = Yup.object().shape({
  otherNationalities: Yup.array().of(Yup.string()).nullable().min(1, "This Field Required"),
});

function SelectOtherNationalities() {
  const { translate } = useTranslate();
  const { next, prev, cancel, context } = useMachine();

  const initialValues = useMemo(() => {
    return context?.addPassport ?? {};
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handlePrev = () => {
    prev();
  };

  const handleSubmit = (values) => {
    next(values);
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <TemplateWizard
        props={{
          title: "Select their other nationalities",
          subTitle: "Other nationalities",
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              {translate("MODULES.CLARA.BTN_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>Add</SubmitButton>,
        }}
      >
        <TemplateLabel label="Other Nationalities" isRequired>
          <ControllerInput
            render={ClaraCountryAutocomplete}
            multiple
            placeholder={translate("MODULES.CLARA.SELECT_ALL_THAT_APPLY")}
            name="otherNationalities"
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default SelectOtherNationalities;
