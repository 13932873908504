import React, { useState } from 'react';
import _ from "lodash";
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import CountryJurisdiction from 'src/modules/generic/components/CountryJurisdictionSelector';
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import FormProps from 'src/modules/generic/forms/interfaces/FormProps';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import TemplateLabel from 'src/modules/generic/templates/Label';
import { Constants } from 'src/v1/utils';
import TemplateFormDefault from 'src/modules/generic/templates/Modal';
import CurrencySelector from "src/components/inputs/Select/CurrencySelector/CurrencySelector";
import cls from './Form.module.scss';
import PurposeOfEntitySelect from '../../Stakeholder/EntityStructures/Cayman/PurposeOfEntityModal/wizard/components/PurposeOfEntitySelect';

const FormGeneralDetailsCayman: React.FC<FormProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {
  const { translate } = useTranslate();
  const [currency, setCurrency] = useState(initialValues?.transactionCurrency ?? 'USD');

  const handleSubmit = (values) => {
    console.log({values})
    onCompleteSubmit && onCompleteSubmit(values);
  };

  const isFormation = initialValues?.initialValues?.isFormation;


  const schema = Yup.object().shape({
    name: Yup.string().required('This field is required').nullable(),
    country: Yup.string().required("This field is required.").nullable(),
    jurisdiction: Yup.string().required('This field is required.').typeError('This field is required.'),
    authorisedShareCapital: Yup.number().nullable().typeError('This field is required.'),
    purposeOfEntity: Yup.string().required("This field is required").typeError("This field is required"),
    purposeOfEntityDescription: Yup.string().when("purposeOfEntity", {
      is: (value) => value === "OTHER" || value === "PERSONAL_ASSETS_HOLDING_COMPANY",
      then: Yup.string().nullable().required("Description is required"),
    }).typeError("Description is required"),
    linkedinURL: Yup.string().matches(Constants.REGEXP.URL_LINKEDIN, { message: "Please enter a valid LinkedIn URL", excludeEmptyString: true }).nullable(),
  });

  return (
    <Form
      defaultValues={initialValues?.groupCompany}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Save changes</SubmitButton> }} props={propsTemplate}>
        <TemplateLabel label='Company name' isRequired>
          <ControllerInput
            name='name'
            render={TextInput}
            placeholder='Company name'
          />
        </TemplateLabel>
        <CountryJurisdiction />
        <TemplateLabel label='Company type'>
          <ControllerInput
            name='type'
            render={TextInput}
            defaultValue={_.startCase(_.toLower(initialValues?.groupCompany?.type))}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
          />
        </TemplateLabel>
        <TemplateLabel label='Company registration number'>
          <ControllerInput name='registeredNumber' render={TextInput} placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')} />
        </TemplateLabel>
        <TemplateLabel label='Incorporation date'>
          <ControllerInput
            name="incorporation_date"
            render={CalendarInputDate}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
          />
        </TemplateLabel>
        <TemplateLabel label='Dissolution date'>
          <ControllerInput
            name="dissolution_date"
            render={CalendarInputDate}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
          />
        </TemplateLabel>
        <TemplateLabel label='Currency'>
          <ControllerInput
            render={CurrencySelector}
            name="transactionCurrency"
            placeholder={translate('MODULES.CLARA.SELECT_CURRENCY')}
            defaultValue={currency}
            onChange={(value: string) => setCurrency(value)}
            disabled={isFormation}
          />
        </TemplateLabel>
        <TemplateLabel label='Authorised share capital'>
          <ControllerInput
            name='authorisedShareCapital'
            render={TextInput}
            placeholder='Authorised share capital'
            type="number"
            leftLabel={currency}
          />
        </TemplateLabel>

        <PurposeOfEntitySelect initialValue={initialValues?.groupCompany?.purposeOfEntity} />

        <TemplateLabel label='Linkedin URL' className={cls.lastInput}>
          <ControllerInput name='linkedinURL' placeholder="Linkedin URL" render={TextInput} />
        </TemplateLabel>
      </Template>
    </Form>
  );
};

export default FormGeneralDetailsCayman;
