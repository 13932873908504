import useUpdateStakeholder from 'src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder';
import useGetStakeholder from './graphql/useGetStakeholder';
import useSession from 'src/modules/session/hooks/useSession';
import moment from 'moment';
import _ from 'lodash';

const useServices = ({ initialValues }) => {
  const { query } = useGetStakeholder({});
  const { startupId } = useSession();
  const stakeholderId = initialValues.stakeholderId;
  const { updateStakeholder } = useUpdateStakeholder({}, {});

  const initialData = async () => {    
    const data = await query({ startupId, stakeholderId });
    
    return {
      stakeholder: data?.stakeholder,
    }
  };

  const SaveStep = async (context) => {
    const { AddBasicInfo, AddMoreDetails } = context;
    
    const stakeholderData = {
      nationality: AddBasicInfo?.nationality,
      address: AddBasicInfo?.address,
      email: AddMoreDetails?.email,
      phoneNumber: AddMoreDetails?.phoneNumber,
      dateOfBirth: moment(AddMoreDetails?.dateOfBirth).format('YYYY-MM-DD'),
      birthAddress: {
        city: AddMoreDetails?.cityOfBirth,
        country: AddMoreDetails?.countryOfBirth,
      },
      nationalities: {
        none: !AddBasicInfo?.hasNationalities,
        countries: [
          ..._.map(AddBasicInfo?.nationalities, (country) => {
            return {
              country
            };
          })
        ],
      },
      profession: AddMoreDetails?.profession,
    };

    const variables = {
      startupId,
      stakeholderId,
      stakeholderData: {
        stakeholder: stakeholderData,
      }
    };

    await updateStakeholder({ variables });
    return context;
  };

  return { initialData, SaveStep };
}

export default useServices;