import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_GROUP_COMPANY = `
query getGroupCompany($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    country {
      name
      code
    }
    jurisdiction
    type
    registeredNumber
    incorporation_date
    nextFinancialYearEnd
    transactionCurrency
    authorisedShareCapital
    purposeOfEntity {
      code
      description
    }
    linkedinURL
  }
}`;

export const useGetGroupCompanyEconomicSubstance = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetGroupCompanyEconomicSubstance;
