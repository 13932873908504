import * as Yup from "yup";
import React, { useEffect, useMemo } from "react";
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import { useDispatch } from "react-redux";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import ClaraCountryAutocomplete from "src/v1/components/Form/ClaraCountryAutocomplete/ClaraCountrySelector";
import { HiddenFieldForm } from "src/components/forms/contents";
import RadioInput from "src/components/inputs/Radio/RadioInput";

function AddBasicInfo() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    let formattedValues = _.cloneDeep(context?.initialData?.stakeholder);

    if (formattedValues?.address?.country?.code) {
      formattedValues.address.country =
        formattedValues?.address?.country?.code;
    } else {
      delete formattedValues.address;
    }

    if (formattedValues?.nationalities?.countries) {
      formattedValues.nationalities.countries =
        formattedValues?.nationalities?.countries?.map(country => country?.code);
    } else {
      delete formattedValues.nationalities;
    }

    return context?.AddBasicInfo ?? formattedValues ?? {};
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(addAssistantText(translate("MODULES.ASSISTANCEV4.ASSISTANCE_MAP_HIT")));

    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    nationality: Yup.string().required("This field is required").typeError("This field is required").nullable(),
    hasNationalities: Yup.boolean().required("This field is required"),
    nationalities: Yup.mixed().when('hasNationalities', {
      is: true,
      then: Yup.array().of(Yup.string()).min(1, translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      otherwise: Yup.array().of(Yup.string()).nullable(),
    }),
    address: Yup.object().shape({
      country: Yup.string().required("This field is required").typeError("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    })
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Add their basic info",
          subTitle: "Personal information"
        }}
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_NEXT")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <TemplateLabel label="Nationality" isRequired>
          <ControllerInput
            placeholder="Select country"
            render={CountrySelector}
            name='nationality'
          />
        </TemplateLabel>
        <TemplateLabel label="Do they have other nationalities?">
          <ControllerInput
            render={RadioInput}
            name='hasNationalities'
          />
        </TemplateLabel>
        <HiddenFieldForm
          fieldsWatch="hasNationalities"
          conditionHideCallback={(value) => value}
        >
          <TemplateLabel label="Other Nationalities" isRequired>
            <ControllerInput
              placeholder="Select all that apply"
              render={ClaraCountryAutocomplete}
              name='nationalities'
            />
          </TemplateLabel>
        </HiddenFieldForm>
        <AddressInput
          name="address"
          label={{ country: "Primary residential address" }}
          zipCodeOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default AddBasicInfo;