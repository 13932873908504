import React, { useCallback, useMemo, useState } from 'react';
import _ from "lodash";
import AutocompleteInput from "src/components/generic/AutocompleteInput";
import useGetCountryAndJurisdiction from 'src/modules/generic/components/CountryJurisdictionSelector/useGetCountryAndJurisdiction';
import Flag from './components/Flag';

function ClaraCountryAutocomplete({...props}: any) {
  const {
    countriesList,
    loading,
  } = useGetCountryAndJurisdiction();

  const [countries, setCountries] = useState([]);

  const filterCountries = useCallback(async (params) => {
    if (countriesList) {
      let firstItems = [];
      if (params?.filters?.searchText === null) {
        firstItems = _.take(countriesList, params?.pagination?.limit);
      } else {
        const searchedItems = _.filter(countriesList, (item: any) => {
          return item.label.toLowerCase().includes(params?.filters?.searchText.toLowerCase());
        });
        firstItems = _.take(searchedItems, params?.pagination?.limit);
      }
      setCountries(
        _.map(firstItems, (item: any) => ({
          id: item.value,
          description: item.label,
          prefix: (
            <Flag url={`https://flagcdn.com/${_.toLower(item.value)}.svg`} />
          ),
        }))
      );
    }
  }, [countriesList]);

  const lazyQueryEmule = useMemo(() => {
    const service = filterCountries;
    const lazy = {
      data: {
        data: countries,
        pageInfo: {
          total: countriesList.length,
          currentPage: 1,
          perPage: 10,
          lastPage: false,
          prevPage: false,
          nextPage: true,
        }
      },
      fetchMore: filterCountries
    }
    return [service, lazy];
  }, [countries, countriesList.length, filterCountries]);

  if (loading) {
    return <p>Loading ...</p>;
  } else {
    return (
      <AutocompleteInput
        placeholder="Type to search country"
        lazyQueryService={lazyQueryEmule}
        multiple={true}
        {...props}
        take={10}
      />
    )
  }
}
export default ClaraCountryAutocomplete;
