import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import { Constants } from "src/v1/utils";
import PurposeOfEntitySelect from "../../components/PurposeOfEntitySelect";

function PurposeOfEntityStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.initialData?.purposeOfEntity ?? context?.PurposeOfEntityStep ?? {};
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(Constants.TRANSLATES.MODAL_PURPOSE_OF_ENTITY_FIRST_TOOLTIP)
    );
    dispatch(
      addAssistantText(Constants.TRANSLATES.MODAL_PURPOSE_OF_ENTITY_SECOND_TOOLTIP)
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const schema = Yup.object().shape({
    purposeOfEntity: Yup.string().required("This field is required").typeError("This field is required"),
    purposeOfEntityDescription: Yup.string().when("purposeOfEntity", {
      is: (value) => value === "OTHER" || value === "PERSONAL_ASSETS_HOLDING_COMPANY",
      then: Yup.string().nullable().required("Description is required"),
    }).typeError("Description is required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "What is the purpose of the entity?",
          skeleton: null,
          subTitle: "Purpose of entity",
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton
            >
              {translate("BUTTON_NEXT")}
            </SubmitButton>
          ),
        }}
      >
        <PurposeOfEntitySelect />
      </TemplateWizard>
    </Form>
  );
}

export default PurposeOfEntityStep;