import _ from 'lodash';

const useGuards = (initialValues) => {
  return {
    isCayman: () => {
      return _.get(initialValues, 'jurisdictionType') === 'CAYMAN';
    },
    isNotCayman: () => {
      const jurisdictionType = _.get(initialValues, 'jurisdictionType');
      return jurisdictionType !== 'CAYMAN' || jurisdictionType == null;
    },
    needMore: (context) => {
      return _.get(context, 'moreToAdd.another');
    },
    noNeedMore: (context) => {
      return _.get(context, 'moreToAdd.another') === false;
    },
    isRenewal: () => {
      return initialValues?.isRenewal;
    },
    isNotRenewal: () => {
      return !initialValues?.isRenewal;
    }
  }
}

export default useGuards;