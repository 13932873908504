import { useMemo } from 'react';
import _ from 'lodash';
import useGetGroupCompanies from 'src/hooks/services/modules/groupCompany/useGetGroupCompanies';
import useSession from "src/modules/session/hooks/useSession";
import useUpsertFormationCompany from "src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany";
import useUpdateGroupCompany from "src/hooks/services/modules/groupCompany/useUpdateGroupCompany";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { manualQuery: getGroupCompanies } = useGetGroupCompanies({ variables: { startupId } });
  const [mutation] = useUpsertFormationCompany({}, {});
  const [updateGroupCompany] = useUpdateGroupCompany({}, {});

  return useMemo(() => ({
    initialData: async () => {
      const groupCompanies = await getGroupCompanies();
      const gcData = _.get(groupCompanies, 'data.getGroupCompanies', []);
      return { ...initialValues, inicialGroupCompanies: gcData };
    },
    SaveStep: async (context) => {
      let companyId = null
      if (context?.HasOperatingCompany?.hasOperatingCompany) {
        let addData;
        if (context?.TellUsAboutYourOperatingCompany?.noHaveWebsite) {
          addData = {
            hasNoWebsiteOrSocialMedia: true,
          }
        } else {
          addData = {
            linkedinURL: context?.TellUsAboutYourOperatingCompany?.website,
          }
        }
        const hasCompanyId = _.has(context, 'TellUsAboutYourOperatingCompany.companyName.id');

        if (hasCompanyId) {
          companyId = context?.TellUsAboutYourOperatingCompany.companyName.id;
          let variables = {
            startupId,
            groupCompanyId: context?.TellUsAboutYourOperatingCompany?.companyName?.id,
            groupCompany: { ...addData },
          };
          await updateGroupCompany(variables);
        } else {
          let variables;
          if (context?.TellUsAboutYourOperatingCompany?.jurisdiction === "OTHER") {
            variables = {
              startupId,
              groupCompany: {
                country: context?.TellUsAboutYourOperatingCompany?.country,
                isHoldingCompany: false,
                jurisdiction: context?.TellUsAboutYourOperatingCompany?.otherJurisdiction,
                name: context?.TellUsAboutYourOperatingCompany?.companyName?.fullName,
                transactionCurrency: "USD",
                ...addData,
              }
            };
          } else {
            variables = {
              startupId,
              groupCompany: {
                country: context?.TellUsAboutYourOperatingCompany?.country,
                isHoldingCompany: false,
                jurisdiction: context?.TellUsAboutYourOperatingCompany?.jurisdiction,
                name: context?.TellUsAboutYourOperatingCompany?.companyName?.fullName,
                transactionCurrency: "USD",
                ...addData,
              }
            };
          }
          const newGroupComapny = await updateGroupCompany(variables);
          companyId = newGroupComapny?.data?.upsertGroupCompany?.id;
          
        }
      }

      const variables = {
        groupCompanyId: context?.initialData?.idGroupCompany,
        startupId,
        groupCompanyData: {
          formationData: {
            businessActivity: {
              activity: context?.WhatDoesYourCompanyDo?.businessActivity,
            }
          }
        }
      }
      if (companyId) {
        variables.groupCompanyData.formationData.businessActivity.operatingCompanyId = companyId
      }
      await mutation(variables);

      return context;
    },
  }), [getGroupCompanies, initialValues, mutation, startupId, updateGroupCompany]);
}

export default useServices;
