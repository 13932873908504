import { default as React, useMemo, useState, useRef } from "react";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import AutocompleteClara from "src/modules/generic/components/Inputs/Autocomplete";
import CountryJurisdiction from 'src/modules/generic/components/CountryJurisdictionSelector';
import TextInput from "src/components/inputs/Text/TextInput";
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import _ from "lodash";

function TellUsAboutYourOperatingCompany() {
  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const [disabled, setDisabled] = useState(false);
  const [disabledMedia, setDisabledMedia] = useState(false);
  const formRef = useRef();

  const initialValues = useMemo(() => {
    return {
      noHaveWebsite: false,
    };
  }, []);

  const handlePrevious = () => {
    prev();
  };

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  const schema = Yup.object().shape({
    companyName: Yup.object().nullable().required("This field is required"),
    country: Yup.string().nullable().required("This field is required"),
    jurisdiction: Yup.string().nullable().required("This field is required"),
    noHaveWebsite: Yup.boolean(),
    website: Yup.string()
      .when("noHaveWebsite", {
        is: false,
        then: Yup.string().url("It must be a valid link").required("This field is required"),
        otherwise: Yup.string().notRequired().nullable(),
      }),
  }); 

  const createOperatingCompany = (value: string) => {
    return { fullName: value };
  };

  const handleChangeGroupCompany = (value) => {
    if (value?.fullName || value === null) {
      setDisabled(false);
      if (formRef?.current) {
        formRef.current.setValue("country", null);
        formRef.current.setValue("jurisdiction", null);
      }
    } else {
      setDisabled(true);
      if (formRef?.current) {
        formRef.current.setValue("country", value?.country?.code);
        formRef.current.setValue("jurisdiction", value?.jurisdiction);
      }
    }
  };

  const companiesList = useMemo(() => {
    const gcData = _.get(context, "initialData.inicialGroupCompanies", []);
    const result = _.map(gcData, gc => ({ value: { id: gc.id, country: gc.country, jurisdiction: gc.jurisdiction }, label: gc.name }));
    return result;
  }, [context]);

  const handleChangeMedia = (value) => {
    setDisabledMedia(value);
    if (value) {
      if (formRef?.current) {
        formRef.current.setValue("website", null);
      }
    }
  }

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      ref={formRef}
    >
      <TemplateWizard
        props={{
          title: "Tell us about your main operating company",
          skeleton: null,
          subTitle: "Nature of underlying business",
          variant: "simple",
        }}
        buttons={{
          previous: <Button onClick={handlePrevious}>Previous</Button>,
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("GENERIC_BUTTON_ADD_GROUP")}
            </SubmitButton>
          ),
        }}
      >
        <TemplateLabel
          label={"Operating company name"}
          isRequired
        >
          <ControllerInput
            render={AutocompleteClara}
            getCreateValue={createOperatingCompany}
            name="companyName"
            options={companiesList}
            placeholder={translate(
              "MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.INDIVIDUAL_NAME_PLACEHOLDER"
            )}
            onChange={handleChangeGroupCompany}
          />
        </TemplateLabel>
        <CountryJurisdiction disabled={disabled} />
        <TemplateLabel label={"Website or social media link"} isRequired>
          <ControllerInput
            render={TextInput}
            name="website"
            disabled={disabledMedia}
            placeholder={"Type here"}
          />
        </TemplateLabel>
        <TemplateLabel>
          <ControllerInput
            render={Checkbox}
            name="noHaveWebsite"
            label={
              "The operating company does not have a website or any social media ccounts."
            }
            variant="col_2"
            onChange={handleChangeMedia}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default TellUsAboutYourOperatingCompany;
